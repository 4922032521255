import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"

export default ({ data }) => {
	const post = data.nodeArticle
	return (
		<Layout>
			<SEO title={ post.title } />
			<section className="bg-grey">
	    	<div className="container">
	    		<div className="row">
	    			<div className="col">

    						<h1 className="text-center py-5">{ post.title }</h1> 
			    			<div style={{  marginBottom: `1.45rem`, width: `100%`, textAlign: 'center' }}>
					          <Img fluid={ post.relationships.field_image.localFile.childImageSharp.fluid } />
					        </div>
		        
    						<div dangerouslySetInnerHTML={{ __html: post.body.value }} /> 
						</div>
		    		</div>
		    	</div>
			</section>	
		</Layout>
	)
}
export const query = graphql`
  query($url: String!) {
    nodeArticle(path: { alias: {eq: $url}}) {
		title
      	body {
        	value
      	}
      	created
        path { alias }
      	relationships {
        field_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
  
    }
  }`